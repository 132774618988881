import "./screens.css";
import "../styles.css";
import { Avatar, Chip, Paper } from "@material-ui/core";
import CustomTable from "../components/CustomTable";
import Subscribe from "../components/Subscribe";

const Contact = (props) => {
  let info = props?.info?.screens?.contact?.content;

  const getCardStyle = (color) => {
    return {
      content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      },
      chip: {
        backgroundColor: "#b81505",
        color: "black",
        margin: ".25rem",
        padding: ".2rem",
        fontSize: "large",
        fontWeight: "350",
      },
    };
  };

  return (
    <div className="container">
      <div className="home">
        <Paper className="page-heading" elevation={3}>
          <h3>{info.label}</h3>
        </Paper>
        <Paper
          elevation={3}
          style={{
            borderRadius: "4rem",
            background: "url(/images/contact-back.JPG)",
            backgroundSize: "cover",
          }}
        >
          <div className="contact-container">
            <div className="contact-image-container">
              <img
                alt={info.label}
                src={info.image}
                className="contact-image"
              />
            </div>
            <div>
              {info?.heading && <h2>{info.heading}</h2>}
              <div style={getCardStyle()?.content}>
                {info.contactList.content.map((el) => {
                  return (
                    <a href={el?.url} target="_blank">
                      <Chip
                        avatar={<Avatar alt={el.title} src={el.image} />}
                        label={el.title}
                        className={"MuiChip-root"}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Contact;

import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import ContactsIcon from "@mui/icons-material/Contacts";
import CottageIcon from "@mui/icons-material/Cottage";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import SchoolIcon from "@mui/icons-material/School";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import React from "react";
import {
  CERTIFICATION,
  CONTACT,
  EDUCATION,
  EXPERIENCE,
  HOME,
} from "../constantsUtils";

const NavigationBar = (props) => {
  const navigationBarStyle = (selected) => {
    return {
      root: {
        backgroundColor: "#b81505",
        color: "#000000",
        borderRadius: "4px",
      },
      tab: {
        color: selected === props.page ? "white" : "#000000",
      },
      icon: {
        color: selected === props.page ? "white" : "#000000",
      },
    };
  };

  const getTabs = (data, index) => {
    if (!data.isDisabled) {
      if (data.name === HOME) {
        return (
          <BottomNavigationAction
            key={data.name}
            label={data.name}
            icon={<CottageIcon style={navigationBarStyle.icon} />}
            style={navigationBarStyle(index).tab}
          />
        );
      } else if (data.name === EXPERIENCE) {
        return (
          <BottomNavigationAction
            key={data.name}
            label={data.name}
            style={navigationBarStyle(index).tab}
            icon={<DeveloperModeIcon style={navigationBarStyle.icon} />}
          />
        );
      } else if (data.name === EDUCATION) {
        return (
          <BottomNavigationAction
            key={data.name}
            label={data.name}
            style={navigationBarStyle(index).tab}
            icon={<SchoolIcon style={navigationBarStyle.icon} />}
          />
        );
      } else if (data.name === CERTIFICATION) {
        return (
          <BottomNavigationAction
            key={data.name}
            label={data.name}
            style={navigationBarStyle(index).tab}
            icon={<VerifiedUserIcon style={navigationBarStyle.icon} />}
          />
        );
      } else if (data.name === CONTACT) {
        return (
          <BottomNavigationAction
            key={data.name}
            label={data.name}
            style={navigationBarStyle(index).tab}
            icon={<ContactsIcon style={navigationBarStyle.icon} />}
          />
        );
      }
    }
  };

  return (
    <BottomNavigation
      showLabels
      value={props.page}
      onChange={(event, newValue) => {
        props.setPage(newValue);
      }}
      style={navigationBarStyle().root}
    >
      {Object.entries(props.info).map(([key, value], index) => {
        return getTabs(value, index);
      })}
    </BottomNavigation>
  );
};

export default NavigationBar;

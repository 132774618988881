import Header from "./components/Header";
import "./styles.css";
import infoSpec from "./infoSpec.json";
import React from "react";
import {
  CERTIFICATION,
  CONTACT,
  EDUCATION,
  EXPERIENCE,
  HOME,
} from "./constantsUtils";
import Home from "./screens/Home";
import Experience from "./screens/Experience";
import Education from "./screens/Education";
import Certification from "./screens/Certification";
import Contact from "./screens/Contact";
import Footer from "./components/Footer";

export default function App() {
  const [page, setPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(null);
  React.useEffect(() => {
    setCurrentPage(Object.keys(infoSpec.header.navigation)[page]);
  }, [page]);
  return (
    <div className="App">
      <div key={"header"} className="header">
        <Header
          info={infoSpec}
          page={page}
          setPage={setPage}
          currentPage={currentPage}
        />
      </div>
      <div key={"content"} className="content">
        {currentPage === HOME.toLowerCase() && <Home info={infoSpec} />}
        {currentPage === EXPERIENCE.toLowerCase() && (
          <Experience info={infoSpec} />
        )}
        {currentPage === EDUCATION.toLowerCase() && (
          <Education info={infoSpec} />
        )}
        {currentPage === CERTIFICATION.toLowerCase() && (
          <Certification info={infoSpec} />
        )}
        {currentPage === CONTACT.toLowerCase() && <Contact info={infoSpec} />}
      </div>
      <div key={"footer"} className="footer">
        <Footer info={infoSpec} />
      </div>
    </div>
  );
}

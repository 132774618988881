import React from "react";
import "./header.css";
import linkedIn from "../images/link.png";
import linkedInHover from "../images/link-hover.png";
import fb from "../images/Fb.png";
import fbHover from "../images/Fb-hover.png";
import github from "../images/Git.png";
import githubHover from "../images/Git-hover.png";
import { DOWNLOAD, FACEBOOK, GITHUB, GMAIL, LINKEDIN } from "../constantsUtils";
import { Paper } from "@material-ui/core";

export const getIcon = (isHover, data) => {
  let icon;
  if (data === LINKEDIN) {
    icon =
      isHover === data ? (
        <img src={linkedInHover} className="subscribeIcon" />
      ) : (
        <img src={linkedIn} className="subscribeIcon" />
      );
  } else if (data === GITHUB) {
    icon =
      isHover === data ? (
        <img src={githubHover} className="subscribeIcon" />
      ) : (
        <img src={github} className="subscribeIcon" />
      );
  } else if (data === GMAIL) {
    icon =
      isHover === data ? (
        <img src={linkedInHover} className="subscribeIcon" />
      ) : (
        <img src={linkedInHover} className="subscribeIcon" />
      );
  } else if (data === DOWNLOAD) {
    icon =
      isHover === data ? (
        <img src={linkedInHover} className="subscribeIcon" />
      ) : (
        <img src={linkedInHover} className="subscribeIcon" />
      );
  } else if (data === FACEBOOK) {
    icon =
      isHover === data ? (
        <img src={fbHover} className="subscribeIcon" />
      ) : (
        <img src={fb} className="subscribeIcon" />
      );
  }
  return icon;
};

const Subscribe = (props) => {
  const [isHover, setIsHover] = React.useState(false);
  const paperStyle = {
    backgroundColor: "#f6f6f7",
  };
  const getSubscribeIcons = () => {
    return Object.entries(props.info).map(([key, value]) => {
      if (!value.isDisabled) {
        return (
          <a
            href={value.url}
            target="blank"
            onMouseEnter={() => setIsHover(key)}
            onMouseLeave={() => setIsHover(null)}
            key={key}
          >
            {getIcon(isHover, key)}
          </a>
        );
      }
    });
  };

  return (
    <>
      {props.isPaper ? (
        <Paper style={paperStyle}>{getSubscribeIcons()}</Paper>
      ) : (
        getSubscribeIcons()
      )}
    </>
  );
};

export default Subscribe;

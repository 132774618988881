import { Paper } from "@material-ui/core";
import HighLightCard from "./HighLightCard";
import NavigationBar from "./NavigationBar";

const MenuHighLights = (props) => {
  return (
    <Paper
      style={{
        // width: 980,
        height: 400,
        marginTop: "20%",
        position: "relative",
        background: "url(/images/testimonials.GIF)",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffff",
        backgroundPosition: "top center",
        backgroundSize: "contain",
      }}
      elevation={3}
    >
      <NavigationBar
        info={props.info.header.navigation}
        page={props.page}
        setPage={props.setPage}
      />
      <HighLightCard
        info={props.info}
        page={props.page}
        currentPage={props.currentPage}
      />
    </Paper>
  );
};

export default MenuHighLights;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ExpandIcon from "@mui/icons-material/Expand";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  CONTACT,
  CONTENT,
  DATA,
  HEADER,
  HEADING,
  LINK,
  LINKS,
  QR_CODE,
  ROWS,
  SUB_CONTENT,
} from "../constantsUtils";
import { Avatar, Chip } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#b81505",
    color: "#000000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useRowStyles = makeStyles({
  dataList: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
  },

  subContent: {
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
  },
  subContentData: {
    marginLeft: 30,
  },
  bulletStyle: {
    marginRight: ".5rem",
  },
});

function Row(props) {
  const { row } = props;
  const { color } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const rowKeys = Object.keys(row).filter(
    (key) => key !== DATA && key !== LINK
  );

  return (
    <React.Fragment>
      <StyledTableRow style={{ backgroundColor: color }}>
        {row[DATA] && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {rowKeys.map((val, index) => {
          return <TableCell>{row[val]}</TableCell>;
        })}
        {row[LINK] && (
          <TableCell>
            <a href={row[LINK]} target="_blank">
              <OpenInNewIcon />
            </a>
          </TableCell>
        )}
      </StyledTableRow>
      {row[DATA] && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {row[DATA][HEADING]}
                </Typography>

                {row[DATA][CONTENT]?.map((el) => (
                  <div className={classes.dataList}>
                    <DoubleArrowIcon className={classes.bulletStyle} />
                    {el}
                  </div>
                ))}
                {row[DATA][SUB_CONTENT] && (
                  <div>
                    <div className={classes.subContent}>
                      <ArrowRightAltIcon className={classes.bulletStyle} />
                      <h4>{row[DATA][SUB_CONTENT]?.title}</h4>
                    </div>
                    <div className={classes.subContentData}>
                      {row[DATA][SUB_CONTENT][CONTENT].map((el) => {
                        return (
                          <div className={classes.dataList}>
                            <ArrowRightIcon className={classes.bulletStyle} />
                            {el}
                          </div>
                        );
                      })}
                      <img
                        src={row[DATA][SUB_CONTENT][QR_CODE]?.url}
                        style={row[DATA][SUB_CONTENT][QR_CODE]?.size}
                      />
                    </div>
                  </div>
                )}
                {row[DATA][LINKS] && (
                  <div>
                    {row[DATA][LINKS]?.map((el) => (
                      <div className={classes.subContentData}>
                        <a href={el?.url} target="_blank">
                          <Chip
                            avatar={<Avatar alt={el.title} src={el.image} />}
                            label={el.title}
                            className={"MuiChip-root"}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function CustomTable(props) {
  const { tableData } = props;

  const paperStyle = {
    overflowX: "scroll",
    marginBottom: "1rem",
  };

  return (
    <div>
      <Paper style={paperStyle}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {tableData[ROWS]?.[0]?.data && (
                <StyledTableCell>
                  <ExpandIcon />
                </StyledTableCell>
              )}
              {tableData[HEADER].map((head) => {
                return <StyledTableCell>{head}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData[ROWS].map((row, index) => (
              <Row
                key={index}
                row={row}
                color={index % 2 == 0 ? "#f2f5f9" : "#ffff"}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

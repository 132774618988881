import React from "react";
import "../styles.css";
import "./footer.css";
import Subscribe from "./Subscribe";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const Footer = (props) => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div key={"footer"} className="container">
      <div className="footer-container">
        <ArrowCircleUpIcon className="footer-scroll" onClick={scrollTop} />
        <div>{props.info.footer.heading}</div>
        <div>
          <Subscribe info={props.info.footer.subscribe} isPaper={false} />
        </div>
      </div>
    </div>
  );
};

export default Footer;

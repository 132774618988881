import "./screens.css";
import "../styles.css";
import { Paper } from "@material-ui/core";
import CustomTable from "../components/CustomTable";

const Education = (props) => {
  let info = props?.info?.screens?.education?.content;

  return (
    <div className="container">
      <div className="home">
        <Paper className="page-heading" elevation={3}>
          <h3>{info.label}</h3>
        </Paper>
        <CustomTable tableData={info.tableData} />
      </div>
    </div>
  );
};

export default Education;

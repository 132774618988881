import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../styles.css";
import "./header.css";
import MenuHighLights from "./MenuHighLights";
import Subscribe from "./Subscribe";

const Header = (props) => {
  const [highlight, setHighLights] = useState(null);

  useEffect(() => {
    setHighLights(
      <MenuHighLights
        info={props.info}
        page={props.page}
        currentPage={props.currentPage}
        setPage={props.setPage}
      />
    );
  }, [props.currentPage]);

  return (
    <div key={"header-container"} className="container">
      <div className="container-content">
        <div key={"header-container-left"} className="header-container-left">
          <Paper>
            <div className="heading">
              <h4> {props.info.header.heading} </h4>
            </div>
          </Paper>
        </div>
        <div key={"header-container-right"} className="header-container-right">
          <Subscribe info={props.info.header.subscribe} isPaper={true} />
        </div>
      </div>
      <div>
        <MenuHighLights
          info={props.info}
          page={props.page}
          currentPage={props.currentPage}
          setPage={props.setPage}
        />
      </div>
    </div>
  );
};

export default Header;

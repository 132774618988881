import { Button, Paper, Slide } from "@material-ui/core";
import { Pagination } from "@mui/material";
import React, { useEffect } from "react";
import "./header.css";
import Zoom from "@mui/material/Zoom";

const HighLightCard = (props) => {
  const [card, setCard] = React.useState(1);
  const [slideStatus, setSlideStatus] = React.useState(false);
  const [info, setInfo] = React.useState(null);
  // const [slideStatus, setSlideStatus] = React.useState(false);

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setCard(1);
  }, [props.info]);

  useEffect(() => {
    setInfo(
      props.info.screens[props.currentPage] != null
        ? props?.info?.screens[props?.currentPage]?.highlights
        : null
    );
    setCard(1);
  }, [props.currentPage]);

  function handleChangePage(event, value) {
    setSlideStatus(false);
    setCard(value);
  }

  useEffect(() => {
    setTimeout(() => {
      setSlideStatus(true);
    }, 200);
  }, [card]);

  const cardStyle = (pos) => {
    let border = "2px solid #425282";
    return {
      flex: ".3",
      marginTop: 25,
      border: border,
      borderTopRightRadius: pos === "left" ? 50 : 0,
      borderBottomRightRadius: pos === "left" ? 50 : 0,
      borderTopLeftRadius: pos === "right" ? 50 : 0,
      borderBottomLeftRadius: pos === "right" ? 50 : 0,
      borderLeft: pos === "left" ? "unset" : border,
      borderRight: pos === "right" ? "unset" : border,
      backgroundColor: "#f6f6f7",
    };
  };

  const getCardDetails = (pos, direction) => {
    return (
      info && (
        <Paper style={cardStyle(direction)}>
          <div className="highlight-card-container">
            <div className="highlight-card-content">
              {info[pos][direction]?.image && (
                <img
                  src={info[pos][direction].image}
                  style={
                    width > 500
                      ? info[pos][direction]?.size
                      : {
                          height:
                            (Number(info[pos][direction]?.size.height) / 10) *
                            4,
                          width:
                            (Number(info[pos][direction]?.size.width) / 10) * 4,
                        }
                  }
                />
              )}
              <div>
                <h2>{info[pos][direction]?.label}</h2>
                <div className="highlight-card-content-data">
                  <div>{info[pos][direction]?.content}</div>
                  {info[pos][direction]?.url && (
                    <a href={info[pos][direction]?.url} target="_blank">
                      Lean More
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )
    );
  };

  return (
    <div className="highlight-card-root">
      <div className="highlight-card">
        <div className="highlight-card-holder">
          {info && (
            <Slide in={slideStatus} direction="left">
              {getCardDetails(card - 1, "left")}
            </Slide>
          )}
          {info && (
            <Slide in={slideStatus} direction="right">
              {getCardDetails(card - 1, "right")}
            </Slide>
          )}
        </div>
      </div>
      {info && (
        <div className="highlight-card-pagination">
          <Pagination
            className="highlight-card-pagination-list"
            count={Object.keys(info).length}
            page={card}
            onChange={handleChangePage}
          />
        </div>
      )}
    </div>
  );
};

export default HighLightCard;

// subscribe
export const LINKEDIN = "linkedIn";
export const GMAIL = "gmail";
export const GITHUB = "github";
export const DOWNLOAD = "download";
export const FACEBOOK = "facebook";

// navigation

export const HOME = "Home";
export const EXPERIENCE = "Experience";
export const EDUCATION = "Education";
export const CERTIFICATION = "Certification";
export const CONTACT = "Contact";

// table
export const HEADER = "header";
export const ROWS = "rows";
export const CONTENT = "content";
export const SUB_CONTENT = "subContent";
export const HEADING = "heading";
export const DATA = "data";
export const LINK = "link";
export const LINKS = "links";
export const QR_CODE = "qrCode";

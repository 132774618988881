import { Avatar, Chip, makeStyles, Paper } from "@material-ui/core";
import { useState } from "react";
import "../styles.css";
import "./screens.css";

const Home = (props) => {
  let info = props?.info?.screens?.home?.content;
  const getCardStyle = (color) => {
    return {
      cardListHeading: {
        borderRadius: 20,
      },
      root: {
        width: 450,
        height: 200,
        position: "relative",
        opacity: "0.9",
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 20,
        backgroundColor: color,
      },
      container: {
        width: "100%",
        height: 40,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
        width: "inherit",
        borderRadius: "inherit",
        backgroundSize: "cover",
        backgroundImage: "url(/images/cardHeader.png)",
      },
      content: {
        display: "flex",
        alignItems: "center",
        marginTop: ".5rem",
        //justifyContent: "space-around",
        justifyContent: "center",
        // padding: ,
        flexWrap: "wrap",
      },
      chip: {
        // backgroundColor: color,
        color: "black",
        margin: ".25rem",
        padding: "1rem",
        fontSize: "large",
        fontWeight: "350",
        backgroundColor: "#f6f6f7",
      },
      headingChip: {
        backgroundColor: "#f6f6f7",
        fontSize: "large",
        fontWeight: "400",
        border: "3px solid #425282",
      },
    };
  };

  return (
    <div className="container">
      <div className="home">
        <Paper className="page-heading" elevation={3}>
          <h3>{info.label}</h3>
        </Paper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {info.content.map((element, index) => {
            return (
              <Paper style={getCardStyle(element.color).root} elevation={3}>
                <div style={getCardStyle(element.color).container}>
                  <div style={getCardStyle(element.color).header}>
                    <Chip
                      label={element.heading}
                      style={getCardStyle(element.chipColor).headingChip}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div style={getCardStyle(index).content}>
                  {element.content.map((el, index) => {
                    return (
                      <Chip
                        avatar={<Avatar alt={el.title} src={el.image} />}
                        label="Clickable"
                        label={el.title}
                        // style={getCardStyle(element.chipColor).chip}
                        className={"MuiChip-root"}
                      />
                    );
                  })}
                </div>
              </Paper>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
